<template>
    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <b-row class="match-height">
        <b-col
          lg="12"
          md="12"
        >
       
        <b-card
          title="Edit Item">
          <b-alert
            v-model="showDismissibleAlert"
            variant="danger"
            dismissible
            class="mb-1"
          >
            <div class="alert-body">
              {{error_message}}
            </div>
          </b-alert>
          
          <b-form @submit="formSubmit">
            
            <b-row>
              
              <b-col md="12">
    
                <b-row>
                  

                  <b-col md="12" >
                    
                      <b-form-group
                        label="Supplier"
                        class="required"
    
                      >
                        <!-- <b-form-select v-model="form.supplier" @change="changeSupplier()">
    
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                        
                          <b-form-select-option :value="supplier._id" v-for="supplier in suppliers" :key="supplier._id">{{ supplier.name | capitalize }}</b-form-select-option>
                        
                        
                        </b-form-select> -->

                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="suppliers"
                          label="name"
                          class="w-100"
                          placeholder="Supplier"
                          @input="changeSupplier()"
                          v-model="form.supplier"
                          :clearable="false"
                        >
                          <template #selected-option="{ name }">
                            <span v-if="name && name.length < 23">{{ name }}</span>
                            <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                          </template>
                        </v-select>
                        
                      </b-form-group>
                    </b-col>
    
                </b-row>

                

                <b-row>
                    <b-col md="6" >
                    
                      <b-form-group
                        label="Category"
                        class="required"
    
                      >
                        <!-- <b-form-select v-model="form.category" @change="changeCategory()">
    
                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                        
                          <b-form-select-option :value="category._id" v-for="category in categories" :key="category._id">{{ category.name | capitalize }}</b-form-select-option>
                        
                        
                        </b-form-select> -->

                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="categories"
                          label="name"
                          class="w-100"
                          placeholder="Category"
                          @input="changeCategory()"
                          v-model="form.category"
                          :clearable="false"
                        >
                        <template #selected-option="{ name }">
                          <span v-if="name && name.length < 23">{{ name }}</span>
                          <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                        </template>
                      </v-select>
                        
                      </b-form-group>
                    </b-col>

                    <b-col md="6" >
                    
                      <b-form-group
                        label="Sub Category"
                        class=""

                      >
                        <!-- <b-form-select v-model="form.sub_category">

                          <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                          <b-form-select-option :value="sub_category._id" v-for="sub_category in subcategories" :key="sub_category._id">{{ sub_category.name | capitalize }}</b-form-select-option>
                    
                    
                        </b-form-select> -->

                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="subcategories"
                            label="name"
                            class="w-100"
                            placeholder="Sub Category"
                          
                            v-model="form.sub_category"
                            :clearable="false"
                          >
                          <template #selected-option="{ name }">
                            <span v-if="name && name.length < 23">{{ name }}</span>
                            <span v-if="name && name.length >= 23">{{ name.substring(0, 23) + '...' }}</span>
                          </template>
                        </v-select>
                    
                      </b-form-group>
                    </b-col>
    
                </b-row>

                <b-row class="mb-2">

                  <b-col md="2" >
                    
                    <b-form-group
                      label="Internal Item Code"
                      class="required"

                    >
                      <b-form-input placeholder="2 to 10 characters" v-model="form.code" autocomplete="off" />
                    </b-form-group>
                  </b-col>

                  <b-col md="4" >
                    
                    <b-form-group
                      label="Item Name"
                      class="required"

                    >
                      <b-form-input placeholder="2 to 50 characters" v-model="form.name" autocomplete="off" />
                    </b-form-group>
                  </b-col>

                  <b-col md="3" >
                    
                    <b-form-group
                      label="Packing"
                      class="required"

                    >
                      <b-form-input placeholder="2 to 50 characters" v-model="form.packing" autocomplete="off" />
                    </b-form-group>
                  </b-col>

                  <b-col md="3" >
                    
                    <b-form-group
                      label="Rate / Unit Price"
                      class="required"

                    >
                      <b-form-input placeholder="0.0" v-model="form.price" autocomplete="off" />
                    </b-form-group>
                  </b-col>



                </b-row>
                

                
                
               
                <b-row>
                  <b-col>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="warning"
                      class="mr-1"
                    >
                      Submit
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      @click="goBack()"
                    >
                      Cancel
                    </b-button>
                  </b-col>
              </b-row>
    
    
              </b-col>
            </b-row>
    
          </b-form>
        </b-card>
        </b-col>
      </b-row>
    
      
    </div>
    </template>
    
    <script>
    
    import {
      BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BBreadcrumb
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import { GET_API, POST_API } from "../../../store/actions.type"
    import VueCropper from 'vue-cropperjs';
    import 'cropperjs/dist/cropper.css';
    import VueTimepicker from 'vue2-timepicker'
    import 'vue2-timepicker/dist/VueTimepicker.css'
    import VueGoogleAutocomplete from 'vue-google-autocomplete';
    import vSelect from 'vue-select';
    
    export default {
      components: {
        BAlert,
        BCard,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormCheckbox,
        BForm,
        BButton,
        BFormSelect,
        BFormSelectOption,
        BFormText,
        BInputGroupPrepend,
        BInputGroup,
        BMedia,
        BAvatar,
        BTable,
        BModal,
        VueCropper,
        BFormRadio,
        BFormTimepicker,
        VueTimepicker,
        BBreadcrumb,
        VueGoogleAutocomplete,
        vSelect
      },
      directives: {
        Ripple,
      },
      data() {
        return {
  
          error_message:null,
          showDismissibleAlert:false,
    
          form :{
            
            supplier:null, 
            category:null,
            sub_category:null,
            code: '',
            name: '',
            packing: '',
            price: '',
            id:''           
          },
          
          suppliers:[],
          categories:[],
          subcategories:[]
          
        }
      },
      methods : {

        
        updateSite(){
          this.form.supplier = null;
          // this.form.names = [{name:''}];
          this.getSuppliers();
          this.form.category = null;
          this.getCategories();
          this.form.sub_category = null;
          this.getSubCategories();

        },
        changeSupplier(){
          this.form.category = null;
          this.getCategories();
          this.form.sub_category = null;
          this.getSubCategories();
        },
        changeCategory(){
          this.form.sub_category = null;
          this.getSubCategories();
        },
        formSubmit(e){
          e.preventDefault();
    
          return this.$store.dispatch(POST_API, {
               data:{
                 items:this.form
               },
               api: '/api/update-supplier-item'
            })
            .then(() => {
                if (this.$store.getters.containsErrors) {
                    this.error_message = this.$store.getters.getErrors;
                    this.showDismissibleAlert = true;
                    window.scrollTo(0,0);
                } else {
                    this.showDismissibleAlert = false;
                    
                    Swal.fire({
                      position: 'center',
                      icon: 'success',
                      title: 'Record Updated Successfully.',
                      showConfirmButton: false,
                      timer: 1500
                    }).then((result) => {
                        // this.$router.go(-1);
                        this.$router.push({ name: 'supplier-items', params: { supplier_id: this.form.supplier._id, category_id: this.form.category._id, sub_category_id: this.form.sub_category == null ? 'all-sub-categories' : this.form.sub_category._id } })
                    });
                    
                }
            });
          
        },
    
        
        breadCrumb(){
          var item = [{
            to:{name:'client-dashboard'},
            text: 'Dashboard',
          },{
            to:null,
            text: 'Inventory'
          },{
            to:null,
            text: 'Settings'
          }, {
            to:  { name: 'supplier-items', params: { supplier_id: this.form.supplier == null ? 'all-suppliers' : this.form.supplier._id, category_id: this.form.category == null ? 'all-categories' : this.form.category._id, sub_category_id: this.form.sub_category == null ? 'all-sub-categories' : this.form.sub_category._id } },
            text: 'Items Management',
          },{
            to:null,
            text: 'Edit Item',
          }];
          return item;
        },
        goBack(){
          this.$router.push({ name: 'supplier-items', params: { supplier_id: this.form.supplier._id, category_id: this.form.category._id, sub_category_id: this.form.sub_category == null ? 'all-sub-categories' : this.form.sub_category._id } })
        },
        getSuppliers(){
          return this.$store.dispatch(POST_API, {
            data:{
                  role:this.$store.getters.currentUser.role,
                  
            },
            api: '/api/all-suppliers'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
              } else {
                  this.suppliers = this.$store.getters.getResults.data;
                  return this.suppliers;
              }
          });
        },
        getCategories() {
          return this.$store.dispatch(POST_API, {
            data: {
              role: this.$store.getters.currentUser.role,
              
              supplier: this.form.supplier == '' ? null : this.form.supplier,
              
            },
            api: '/api/all-suppliers-categories'
          })
          .then(() => {
            if (this.$store.getters.containsErrors) {
              this.error_message = this.$store.getters.getErrors;
            } else {
              this.categories = this.$store.getters.getResults.data;
              return this.categories;
            }
          });
        },
        getSubCategories() {
          return this.$store.dispatch(POST_API, {
            data: {
              role: this.$store.getters.currentUser.role,
              
              supplier: this.form.supplier == '' ? null : this.form.supplier,
              category: this.form.category == '' ? null : this.form.category,
            },
            api: '/api/all-suppliers-sub-categories'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                this.subcategories = this.$store.getters.getResults.data;
                return this.categories;
              }
            });
        }, 

        getDetail() {
          return this.$store.dispatch(POST_API, {
            data: {
              id: this.$route.params.id,
            },
            api: '/api/supplier-item-detail'
          })
            .then(() => {
              if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
              } else {
                var data = this.$store.getters.getResults.data;
                
                this.form.supplier = data.supplier;
                this.form.category = data.category;
                this.form.sub_category = data.sub_category == null ? '' : data.sub_category; 
                
                this.form.code = data.code == null ? '' : data.code;
                this.form.name = data.name;
                this.form.packing = data.packing;
                this.form.price =  data.unit_price;
                
                this.form.id = data._id;
                this.getSuppliers();
                this.getCategories();
                this.getSubCategories();
                return this.form;
              }
            });
        }
             
    
      },
      mounted(){
        
        
        this.getDetail();
      }
    }
    </script>

    <style lang="scss">
        @import '@core/scss/vue/libs/vue-select.scss';
    </style>
    